
export class ADG {

  red = 'red';

  // Primary colours
  adgRed = '#d04437';
  adgNavy = '#205081';
  adgLightGrey = '#f5f5f5';
  adgCharcoal = '#333333';
  adgBlue = '#3572b0';
  adgPaleBlue = '#ebf2f9';
  adgMediumGray = '#707070';
  adgGreen = '#14892c';
  adgYellow = '#f6c342';

  //adgPrimaryColors = [adgRed, adgNavy, adgLightGrey, adgCharcoal, adgBlue, adgPaleBlue, adgMediumGray, adgGreen, adgYellow];

  // Secondary colours
  adgRosie = '#f691b2';
  adgGray = '#999999';
  adgAshGray = '#cccccc';
  adgSilver = '#e9e9e9';
  adgBrown = '#815b3a';
  adgCheetoOrange = '#f79232';
  adgTan = '#f1a257';
  adgLightBrown = '#d39c3f';
  adgCyan = '#59afe1';
  adgSlate = '#4a6785';
  adgCoolBlue = '#84bbc6';
  adgLimeGreen = '#8eb021';
  adgMidGreen = '#67ab49';
  adgViolet = '#654982';
  adgMauve = '#ac707a';
  adgBrightPink = '#f15c75';

  getPrimaryColors = () => {
    return [
      this.adgRed,
      this.adgNavy,
      this.adgLightGrey,
      this.adgCharcoal,
      this.adgBlue,
      this.adgPaleBlue,
      this.adgMediumGray,
      this.adgGreen,
      this.adgYellow];
  };

  getSecondaryColors = () => {
    return [
      this.adgRosie,
      this.adgBrown,
      this.adgCheetoOrange,
      this.adgTan,
      this.adgLightBrown,
      this.adgCyan,
      this.adgGray,
      this.adgSlate,
      this.adgCoolBlue,
      this.adgLimeGreen,
      this.adgSilver,
      this.adgAshGray,
      this.adgMidGreen,
      this.adgViolet,
      this.adgMauve,
      this.adgBrightPink];
  };

  getWhiteContrastColors = () => {
    return [
      this.adgRed,
      this.adgBlue,
      //this.adgPaleBlue,
      this.adgGreen,
      this.adgRosie,
      this.adgBrown,
      this.adgCheetoOrange,
      this.adgTan,
      this.adgNavy,
      this.adgLightBrown,
      this.adgCyan,
      this.adgCharcoal,
      this.adgGray,
      this.adgSlate,
      this.adgCoolBlue,
      this.adgLimeGreen,
      //this.adgSilverlver,
      this.adgAshGray,
      this.adgMidGreen,
      this.adgMediumGray,
      this.adgViolet,
      this.adgMauve,
      this.adgBrightPink];
  };

}

export default new ADG();