import constants from '../../../shared/model/Constants';
import PullRequestsIcon from '@atlaskit/icon/glyph/bitbucket/pullrequests';
import GearIcon from '@atlaskit/icon/glyph/settings';
import HomePage from '../../pages/HomePage';
import LawsPage from '../../pages/LawsPage';
import NationalAnthemPage from '../../pages/NationalAnthemPage';
import StatesAndTerritoriesPage from '../../pages/StatesAndTerritoriesPage';
import HomeIcon from '@atlaskit/icon/glyph/home-filled';
import DashboardIcon from '@atlaskit/icon/glyph/dashboard';
import DetailViewIcon from '@atlaskit/icon/glyph/detail-view';
import BillingIcon from '@atlaskit/icon/glyph/billing';
import CodeIcon from '@atlaskit/icon/glyph/code';
import CodingPage from '../../pages/CodingPage';
import OverviewIcon from '@atlaskit/icon/glyph/overview';
import ObservationsIcon from '@atlaskit/icon/glyph/watch';
import AddItemIcon from '@atlaskit/icon/glyph/add-item';
import RecommendationsIcon from '@atlaskit/icon/glyph/decision';
import SignalsIcon from '@atlaskit/icon/glyph/graph-line';
import TeamIcon from '@atlaskit/icon/glyph/people-group';
import AudioIcon from '@atlaskit/icon/glyph/audio';
import MediaServicesScaleLargeIcon from '@atlaskit/icon/glyph/media-services/scale-large';
import EditorNumberListIcon from '@atlaskit/icon/glyph/editor/number-list';
import LocationIcon from '@atlaskit/icon/glyph/location';
import EditorPanelIcon from '@atlaskit/icon/glyph/editor/panel';

export class TeamAppNav {

  getNavInfo = () => {
    const navInfo = [];
    navInfo.push({
      path: '/',
      title: 'Home',
      icon: HomeIcon,
      component: HomePage
    });
    navInfo.push({
      path: '/laws',
      title: 'Laws',
      icon: EditorNumberListIcon,
      component: LawsPage
    });
    navInfo.push({
      path: '/states-and-territories',
      title: 'States and Territories',
      icon: LocationIcon,
      component: StatesAndTerritoriesPage
    });
    navInfo.push({
      path: '/national-anthem',
      title: 'National Anthem',
      icon: AudioIcon,
      component: NationalAnthemPage
    });
    navInfo.push({
      path: '/coding',
      title: 'Coding',
      icon: CodeIcon,
      component: CodingPage
    });
    return navInfo;
  }

}

export default new TeamAppNav();
