import React, { PureComponent } from 'react';
import ContentWrapper from '../components/widget/ContentWrapper';
import constants from '../../shared/model/Constants';
import MapImage from '../images/FoodLandMap.png';
import PageTitle from '../components/widget/PageTitle';
import listenerGroupRegistry from '../../shared/util/ListenerGroupRegistry';
import { ToggleStateless as Toggle } from '@atlaskit/toggle';


export default class StatesAndTerritoriesPage extends PureComponent {

  state = {
    toggleOn: true
  };

  onToggleChange = () => {
    this.setState({
      toggleOn: !this.state.toggleOn
    });
  };

  renderToggle = () => {
    return (
      <Toggle
        size="regular"
        isChecked={this.state.toggleOn}
        onChange={this.onToggleChange}
      />
    );
  };

  renderText = () => {
    return (
      <div className="toolbar-item">
        <h2>Introduction</h2>
        <p>
          {constants.countryName} has three states and one territory.
          Click the toggle to show and hide the map.
          {this.renderToggle()}
        </p>

        <h3>
        State: Fresh Juicy Meat</h3>
        <p>
          In Fresh Juicy Meat you can enjoy your favorite currys, 
          stir frys, steak, cassarole and much more.Fresh Juicy Meat 
          is well known for its exesive animal farming and provides 
          foodland with enough meat to feed everyone. The people of 
          Fresh Juicy Meat are required to be butchers and to follow 
          their family traditions. The people of Fresh Juicy Meat
          take their butchering so seriosly that they only sleep 10 minutes 
          a day to ensure that they have provided their entire contry with enough
          iron and protein to carry oxigene to their lungs and heart.
        </p>

        <h3> State:Veggy Hedgy</h3>
        <p>
        Veggy Hedgy is well known for its refreshing vegtables that grow instantly.
        All you have to do is throw a seed onto the soil in Veggy Hedgy and before
        your eyes vegtables will grow. what makes vegy hedgy so unique is that
        Veggy Hedgy is the only state that has the ability to instantly grow vegetables
        which puts presure on them to supply the contry with vegetables.
        </p>

        <h3>State:Dairy Delight </h3>
        <p> Dairy Delight is the land of calcium and has millions of cows that get 
          freshly milked every day. this ensures that the quality of the milk is above standards 
          because everything in Dairy Delight is made from milk. The fresher the 
          tastier and the tastier the the more customers they get. The citys in Dairy Delight
          are all dedicated to making something out of Dairy. For Example, custard cowbell 
          and pannacotta palace.
        </p>

        <h3>Teritory:Carbohydrate Creator</h3>
        <p>in Carbohydrate Creator their are millions of rice paddys and wheat farms for
            crating bread and flour. their are also thousand of chickens which help to collect eggs for 
            making pasta and stuff that envolves lots of Carbohydrates.
        </p>
      </div>
    );
  };

  renderTextPanel = () => {
    return (
      <div className="toolbar-item">
        {this.renderText()}
      </div>
    );
  };

  renderMapPanel = () => {
    return (
      <div className="toolbar-item">
        <img src={MapImage} width="800px" align="right" />
      </div>
    );
  };

  render() {
    return (
      <ContentWrapper>
        <PageTitle>States and Territories</PageTitle>
        <div className="toolbar-justify">
          {this.renderTextPanel()}
          {this.state.toggleOn ? this.renderMapPanel() : null}
       </div>
      </ContentWrapper>
    );
  }
}
