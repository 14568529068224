import constants from './../model/Constants';

export class Util {

  uuid = () => {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
      s4() + '-' + s4() + s4() + s4();
  };

  parseDate = (dateText) => {
    return new Date(Date.parse(dateText));
  };

  parseDateAsTimestamp = (dateText) => {
    return Date.parse(dateText);
  };

  // Example result: 'Sep 15, 2017'
  formatDate = (date) => {
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    return constants.abbreviatedMonthNames[monthIndex] + ' ' + day + ', ' + year;
  };

  cloneDate = (date) => {
    return new Date(date.getTime());
  };

  rollDays = (date, days) => {
    date.setDate(date.getDate() + days);
  };

  rollWeeks = (date, weeks) => {
    date.setDate(date.getDate() + weeks * 7);
  };

  rollMonths = (date, months) => {
    const preferredDayOfMonth = date.getDate();
    let dayIncrementSize = months > 0 ? 1 : -1;
    let requiredMonthRollCount = months > 0 ? months : -1 * months;
    let prevMonth = date.getMonth();
    let monthRollCount = 0;
    while (monthRollCount < requiredMonthRollCount) {
      date.setDate(date.getDate() + dayIncrementSize);
      let currentMonth = date.getMonth();
      if (currentMonth !== prevMonth) {
        monthRollCount++;
      }
      prevMonth = currentMonth;
    }

    const resultingMonth = date.getMonth();
    date.setDate(preferredDayOfMonth);
    let adjustedMonth = date.getMonth();
    while (adjustedMonth !== resultingMonth) {
      this.rollDays(date, -1);
      adjustedMonth = date.getMonth();
    }
  };

  computeAverage = (values) => {
    if (values && values.length > 0) {
      let total = 0;
      for (let i = 0; i < values.length; i++) {
        total = total + values[i];
      }
      return total / values.length;
    } else {
      debugger;
      return undefined;
    }
  };

}

export default new Util();
