import React, { PureComponent } from 'react';
import Button from '@atlaskit/button';
import ContentWrapper from '../components/widget/ContentWrapper';
import constants from '../../shared/model/Constants';
import PageTitle from '../components/widget/PageTitle';

export default class CodingPage extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      quizStarted: false,
      answerVisible: false,
      quizItems: this.buildQuizItems()
    }
  }

  buildQuizItems = () => {
    return [{
      title: `Alerts`,
      code: `alert('Hello world')`,
      question: `What will this code do?`,
      answer: `It will show an alert box containing <code>Hello world</code>`,
      allowTryIt: true,
      tryItInstructions: ''
    }, {
      title: `Change a heading`,
      code: `
const firstHeader = document.querySelector('h1');
firstHeader.innerHTML = 'Coding is fun';
firstHeader.style.color = 'red';
firstHeader.style.textDecoration = 'underline';
`,
      question: `What will this code do?`,
      answer: `It will change the heading text, its colour and unlerline it.`,
      allowTryIt: true,
      tryItInstructions: ''
    }, {
      title: `Location`,
      code: `
navigator.geolocation.getCurrentPosition(function(position) {
  alert('Latitude: ' + position.coords.latitude + ', Longitude: ' + position.coords.longitude);
});
`,
      question: `What will this code do?`,
      answer: `It will show your location in an alert.`,
      allowTryIt: true,
      tryItInstructions: ''
    }, {
      title: `Background colour`,
      code: `
document.body.style.background = 'red';
setTimeout(() => {
  document.body.style.background = 'white';
}, 3000)`,
      question: `What will this code do?`,
      answer: `It will change the background color to red, wait 3 seconds and then change it back to white.`,
      allowTryIt: true,
      tryItInstructions: ''
    }, {
      title: `Getting the page title`,
      code: `alert('The page title is "' + document.title + '"')`,
      question: `What does this code do?`,
      answer: `It shows an alert with a message showing the page title.`,
      allowTryIt: true,
      tryItInstructions: ''
    }, {
      title: `Setting the page title`,
      code: `document.title = 'I love food'`,
      question: `What does this code do?`,
      answer: `It sets the page title to 'I love food'. The page title appears 
      in the browser tab.`,
      allowTryIt: true,
      tryItInstructions: ''
    }, {
      title: `Loops`,
      code: `
for (let num = 0; num < 2; num++) {
  alert('num = ' + num);
}`,
      question: `What does this code print in the console?`,
      answer: `Two alerts will appear. The first will be "num = 0" and the 
      second will be "num = 1".`,
      answerIsCode: false,
      allowTryIt: true
    }, {
      title: `If statement`,
      code: `
let num = 1;
if (num === 2) {
  alert('Oh my.');
} else {
  alert('Jeepers.');
}
`,
      question: `What will this code do?`,
      answer: `Since <code>num</code> has a value of 1, the <code>else</code> part
      of the <code>if</code> statement will execute so an alert with the text
      "Jeepers" will appear.`,
      allowTryIt: true,
      tryItInstructions: ''
    }, {
      title: `Bad code`,
      code: `
let num = 1;
if (num = 2) {
  alert('Oh my.');
} else {
  alert('Jeepers.');
}
`,
      question: `What is wrong with this code?`,
      answer: `The <code>if</code> should be based on a condition, 
      but <code>num = 2</code> is an assignment. It is setting <code>num</code> to
      the value <code>2</code>. Even though <code>num<code> was equal to <code>2</code>
      before reaching the <code>if</code>, the assignment <code>num = 2</code> returns
      the value <code>2</code> so an alert containing "Oh my" will appear.`,
      allowTryIt: true,
      tryItInstructions: ''
    }];
  };

  onStartQuiz = () => {
    this.setState({
      quizStarted: true,
      currentQuestionNumber: 0
    });
  };

  onShowAnswer = () => {
    this.setState({
      answerVisible: true
    });
  };

  onNextQuestion = () => {
    this.setState({
      currentQuestionNumber: this.state.currentQuestionNumber + 1,
      answerVisible: false
    });
  };

  onTryIt = (quizItem) => {
    eval(quizItem.code);
  };

  renderQuizItemAnswer = (quizItem) => {
    if (quizItem.answerIsCode) {
      return (
        <code>
          <pre className="listing">
            {quizItem.answer.trim()}
          </pre>
        </code>
      );
    } else {
      return (
        <p dangerouslySetInnerHTML={{__html: quizItem.answer.trim()}}>
        </p>
      );
    }
  }

  renderTryIt = (quizItem) => {
    return (
      <div className="newMainSection">
        <div style={{display: 'flex'}}>
          <div>
            <Button
              appearance="danger"
              onClick={() => this.onTryIt(quizItem)} 
            >
              Try it
            </Button>
          </div>
          <div style={{marginLeft: '10px', paddingTop: '6px'}}>
            {quizItem.tryItInstructions ? (<p>{quizItem.tryItInstructions}</p>) : null}
          </div>
        </div>
      </div>
    );
  };

  renderQuizItemAnswerSection = (quizItem) => {
    if (this.state.answerVisible) {
      return (
        <div>
          <h4>Answer</h4>
          {this.renderQuizItemAnswer(quizItem)}
          {quizItem.allowTryIt ? this.renderTryIt(quizItem) : null}
          <div className="newMainSection">
            <Button
              appearance="primary"
              onClick={() => this.onNextQuestion()} 
            >
              Next
            </Button>
          </div>
        </div>
      );
    } else {
      return (
        <Button
          appearance="primary"
          onClick={() => this.onShowAnswer(quizItem)} 
        >
          Show answer
        </Button>
      );
    }
  }

  renderQuizItem = (quizItem) => {
    return (
      <div>
        <h3>
          {quizItem.title}
        </h3>
        <h4>
          {quizItem.question}
        </h4>
        <code>
          <pre className="listing">
            {quizItem.code.trim()}
          </pre>
        </code>
        {this.renderQuizItemAnswerSection(quizItem)}
      </div>
    );
  }

  renderQuizQuestions = () => {
    if (this.state.currentQuestionNumber < this.state.quizItems.length) {
      const quizItem = this.state.quizItems[this.state.currentQuestionNumber];
      return this.renderQuizItem(quizItem);
    } else {
      return (
        <div>
          You finished the quiz. Thanks.
          <div className="newMainSection">
            {this.renderStartQuizButton()}
          </div>
        </div>
      );
    }
  };

  renderStartQuizButton = () => {
    return (
      <Button
        appearance="primary"
        onClick={this.onStartQuiz} 
      >
        Start the coding quiz
      </Button>
    );
  };

  renderQuiz = () => {
    if (this.state.quizStarted) {
      return this.renderQuizQuestions();
    } else {
      return this.renderStartQuizButton();
    }
  }

  render() {
    return (
      <ContentWrapper>
        <PageTitle>Coding</PageTitle>
        <div>
          <p>
            Everyone in {constants.countryName} learns how to code.
          </p>
        </div>
        <div className="newMainSection">
          {this.renderQuiz()}
        </div>
      </ContentWrapper>
    );
  }
}
