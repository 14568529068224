import React, { PureComponent } from 'react';
import adg from '../../shared/model/ADG';
import ContentWrapper from '../components/widget/ContentWrapper';
import constants from '../../shared/model/Constants';
import PageTitle from '../components/widget/PageTitle';

export default class HomePage extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      population: 746911365275697,
      currency: 34
    }
  }

  componentDidMount() {
    this.populationTimer = setInterval(this.updatePopulation, 500);
    this.currencyTimer = setInterval(this.updateCurrency, 500);
  }

  componentDidUnmount() {
    clearInterval(this.populationTimer);
    clearInterval(this.currencyTimer);
  }

  updateCurrency = () => {
    const changeFactor = Math.random();
    const changeInteger = Math.round(changeFactor * 4);
    const changeAmount = changeInteger - 1;
    this.setState({
      currency: this.state.currency + changeAmount
    });
  };

  updatePopulation = () => {
    const changeFactor = Math.random();
    const changeInteger = Math.round(changeFactor * 4);
    const changeAmount = changeInteger - 1;
    this.setState({
      population: this.state.population + changeAmount
    });
  };

  render() {
    return (
      <ContentWrapper>
        <PageTitle>About {constants.countryName}</PageTitle>
        <div>
          <h2>History</h2>
          <p>
            Once upon a time back when the gondwanaland was still connected
            there was a massive earthquake.Debris from the earthquake
            connected together in four major locations creating four
             different types of food. one looked like a vegetable, 
             on like a piece of meat, one like a cow for dairy and 
             the last looked like a potatoe which symbolises carbohydrates.
             50 million years later all of the islands made from debris 
             from the earthquakes came together to make what is know 
              as {constants.countryName}. {constants.countryName}'s' soil is very rich because the debris 
              it came of on was volcanic soil which is the richest soil in 
              the world.

          </p>

          <h2>Tourism</h2>
          <p>
            Popular tourist destinations are things like the broccoli 
            treehouse and the Curly Whirly Tower.for those dare devils 
            the carrot drawbri  dge is the right place for you because a 
            giant carrot is sliced in half and placed over a 500 metre
            canyon. most tourists watch the amazing foodie opera at 
            the eggplant stadium which holds 50 million people. all
              seats are 5 star so people with less money are not disadvantaged.
          </p>

          <h2>Music</h2>

          <div style={{
            float: 'right',
            width: '560px',
            marginLeft: '20px'
          }}>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/3EGiNo32EFE"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            >
            </iframe>
          </div>

          <p>
            The music in {constants.countryName} is known for its creativity and amazing
            instruments. These instruments make sounds that sound imposible to make. All
            of the instruments in {constants.countryName} are created using different 
            types food which rusult in interesting sounds and tones.
          </p>

          <h2>Population</h2>
          <p>
            The current population of {constants.countryName}  
             is: <span style={{color: adg.adgBlue}}><b>{this.state.population}</b></span>.
          </p>

          <h2>Employment</h2>
          <p>
            In {constants.countryName} finding a job is easy. Firstly, you need to take 
            this test below. Next add the location and type of job that you are looking for and within 0.000156378  seconds someone
            will email back saying if you are successful or not.
          </p>

          <h2>Sport</h2>
          <p>
            There are three types of sport {constants.countryName} plays. The first sport is called
            celery snatch, the second NRL and the third basketball. Both NRL and basketball
            teams are undefeated by any other country in the world. 
            The NRL team is called
            the Broccoli Bombers and the basketball team is called the Dairy Dunkers.
          </p>

          <h2>Environment</h2>
          <p>
            {constants.countryName} helps the environment by using the saying, "go around not through".
            which basically means to build around trees not through them, this helps us 
            maintain our oxygiene levels.
          </p>

          <h2>Currency</h2>
          <p>
            The name of the currency of {constants.countryName} is called the {constants.currencyName}.
            
            1 {constants.currencyName} = <span style={{color: adg.adgBlue}}><b>{this.state.currency}</b></span>  billon 
            American dollars.
            This high conversion rate was caused by inflation during a famine a few years ago.
          </p>
          <h2>Motto</h2>
          <p>
          {constants.countryName}'s motto is "manducatis" because it means "you eat" in latin.
          </p>
       </div>
      </ContentWrapper>
    );
  }
}
