import React, { PureComponent } from 'react';
import ContentWrapper from '../components/widget/ContentWrapper';
import constants from '../../shared/model/Constants';
import PageTitle from '../components/widget/PageTitle';
import listenerGroupRegistry from '../../shared/util/ListenerGroupRegistry';
import { ToggleStateless as Toggle } from '@atlaskit/toggle';
import LawsImage from '../images/laws.jpg';

export default class LawsPage extends PureComponent {

  constructor(props) {
    super(props);
    const laws = this.buildLaws();
    console.log('Laws:', laws);
    debugger;
    this.state = {
      laws: laws
    };
  }
  buildLaws = () => {
    const laws = [{
      name: ``,
      description: ``
    }, {
      name: `Free Religon`,
      description: `Religious freedom protects people's right to live, speak, 
      and act according to their beliefs.`
    }, {
      name: 'Free speech',
      description: `Freedom of speech is a inportant human right. 
      Free speach lets Foodies say, believe in and support whatever they want
      so long as it doesn't harm others.`
    }, {
      name: 'Elected government',
      description: `An elected government provides the opportunity for the people of
      Foodland to choose who they want to govern.
      `
    }, {
      name: 'Compulsory education',
      description: `Compulsory education ensures all kids learn about all the
      usefull things they need for a good life. It improves
      their skills and will make Foodland better.
      `
    }, {
      name: 'No guns',
      description: `Gun-free Foodand is intended to reduce violent crime,
      injuries, deaths and mass shootings in all locations. 
      The gun free Foodland policy has made Foodland a much safer
       and more enjoyable place to live.`
    }, {
      name: 'Always be peaceful',
      description: `Foodies are allways peaceful mainly because they live forever 
      and have heaps of time to enjoy life. Foodies consider jobs as fun because they 
      live forever and think it's the most useful way to use their time.`
    }, {
      name: '',
      description: ``
    }, {
      name: '',
      description: ``
    }, {
      name: '',
      description: ``
    }, {
      name: '',
      description: ``
    }, {
      name: '',
      description: ``
    }, {
      name: '',
      description: ``
    }, {
      name: '',
      description: ``
    }]
    return laws;
  }

  renderLaw = (law, index) => {
    if (!law.name) {
      return null;
    }
    return (
      <div key={'law-' + index} style={{marginBottom: '20px'}}>
        <b>{law.name}</b>: {law.description}
      </div>
    );
  };

  renderLaws = () => {
    if (!this.state) {
      return (
        <p>
          No laws
        </p>
      );
    } else {
      return this.state.laws.map((law, index) => {
        return this.renderLaw(law, index);
      });
    }
  };

  renderImage = () => {
    return (
      <img src={LawsImage} width="100%" align="right" />
    );
  }

  render() {
    return (
      <ContentWrapper>
        <PageTitle>Laws</PageTitle>
        <div className="content-columns">
          <div className="content-column-half-width">
            <h1>    The main laws of Foodland
            </h1>
            {this.renderLaws()}
          </div>
          <div className="content-column-half-width">
            {this.renderImage()}
          </div>
       </div>
      </ContentWrapper>
    );
  }
}
