import listenerGroupRegistry from './ListenerGroupRegistry';
import util from './Util';

export default class ListenerGroup {

  listenerIdsToListeners = {};
  maxListenersCount = 0;

  constructor(registryName) {
    this.registryName = registryName;
    listenerGroupRegistry.addRegistry(this);
  }

  getListenerCount = () => {
    return Object.keys(this.listenerIdsToListeners).length;
  };

  getMaxListenerCount = () => {
    return this.maxListenersCount;
  };

  registerListener = (listener) => {
    if (listener.listenerUuid) {
      // previously registered and possibly unregistered.
    }
    listener.listenerUuid = util.uuid();
    this.listenerIdsToListeners[listener.listenerUuid] = (listener);
    const listenerCount = this.getListenerCount();
    if (listenerCount > this.maxListenersCount) {
      this.maxListenersCount = listenerCount;
    }
  };

  unregisterListener = (listener) => {
    delete this.listenerIdsToListeners[listener.listenerUuid];
  };

  notifyListeners = (object, context) => {
    const uuids = Object.keys(this.listenerIdsToListeners);
    for (let i = 0; i < uuids.length; i++) {
      const uuid = uuids[i];
      const listener = this.listenerIdsToListeners[uuid];
      try {
        listener(object, context);
      } catch (e) {
        console.error('Exception occurred whilst calling listener: ', e);
      }
    }
  };

}
