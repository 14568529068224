import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Switch, Route } from 'react-router';
import { HashRouter } from 'react-router-dom';
import App from './App.jsx';
import teamAppNav from '../components/nav/NavItems';

export default class MainRouter extends PureComponent {
  constructor() {
    super();
    this.state = {
      navOpenState: {
        isOpen: true,
        width: 304,
      }
    }
  }

  getChildContext () {
    return {
      navOpenState: this.state.navOpenState,
    };
  }

  onNavResize = (navOpenState) => {
    this.setState({
      navOpenState,
    });
  };

  render() {
    const navInfo = teamAppNav.getNavInfo();
    const appRoutes = navInfo.map((nav) => {
      return <Route exact key={nav.path} path={nav.path} component={nav.component} />;
    });
    return (
      <HashRouter>
        <Switch>
          <Route
            path='/'
            component={(props) => (
              <App
                onNavResize={this.onNavResize}
                {...props}
              >
                {appRoutes}
              </App>
            )}
          />
        </Switch>
      </HashRouter>
    );
  }

}

MainRouter.childContextTypes = {
  navOpenState: PropTypes.object,
};
