import React, { PureComponent } from "react";
import styleConstants from './../StyleConstants';

export default class ClickNavItem extends PureComponent {

  render() {
    return (
      <table href="#/"
         style={{
           cursor: 'pointer',
           marginLeft: '12px'
         }}
         onClick={this.props.onClick}
      >
        <tbody style={styleConstants.noTableBorderStyle}>
          <tr>
            <td
              style={{
                width: '20px'
              }}
            >
              {this.props.icon}
            </td>
            <td>
              {this.props.text}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  renderOLD() {
    //backgroundColor: 'rgba(9, 30, 66, 0.04)',
    return (
      <a href="#/"
        style={{
          cursor: 'pointer'
        }}
        key={this.props.key}
        onClick={this.props.onClick}
      >
        <span
          style={{
            display: 'flex',
            webkitBoxAlign: 'center',
            webkitAlignItems: 'center',
            borderRadius: '3px',
            mozBoxSizing: 'border-box',
            padding: '8px 12px, 8px 12px',
            color: '0052CC',
            textDecoration: 'none',
            height: '40px'
          }}
        >
          <span
            style={{
              display: 'flex',
              webkitBoxAlign: 'center',
              marginRight: '16px'
            }}
          >
            {this.props.icon}
          </span>
          <span
            style={{
              display: 'flex',
              webkitBoxDirection: 'normal',
              webkitBoxOrient: 'vertical',
              msFlexDirection: 'column',
              webkitFlexDirection: 'column',
              flexGrow: 1,
              msFlexPositive: 1,
              webkitFlexGrow: 1,
              margin: 0,
              overflow: 'hidden'
            }}
          >
            <span
              style={{
                display: 'block',
                webkitFlex: '1 1 auto',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                lineHeight: 1.1428571428571428
              }}
            >
                  {this.props.text}
            </span>
          </span>
        </span>
      </a>
    );
  }
}
