
export class Constants {

  countryName = 'Foodland';
  peopleName = 'Foodie';
  currencyName = 'Foodlandian';

}

export default new Constants();
