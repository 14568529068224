import React from 'react';
import styled from 'styled-components';
import { akGridSizeUnitless } from '@atlaskit/util-shared-styles';

const Padding = styled.div`
  margin: ${akGridSizeUnitless * 4}px ${akGridSizeUnitless * 8}px;
  padding-bottom: ${akGridSizeUnitless * 3}px;
`;

//export default ({ children }) => (
//  <Grid layout="fixed">
//    <GridColumn>
//      <Padding>{children}</Padding>
//    </GridColumn>
//  </Grid>
//)
//
//export default ({ children }) => (
//  <div>
//      {children}
//  </div>
//)

export default ({ children }) => (
  <Padding>
    {children}
  </Padding>
)
