import React, { PureComponent } from 'react';
import Button from '@atlaskit/button';
import ContentWrapper from '../components/widget/ContentWrapper';
import PageTitle from '../components/widget/PageTitle';
import { ToggleStateless as Toggle } from '@atlaskit/toggle';


export default class NationalAnthemPage extends PureComponent {

  state = {
    toggleOn: true,
    playing: false
  };

  onToggleChange = () => {
    this.setState({
      toggleOn: !this.state.toggleOn
    });
  };

  renderToggle = () => {
    return (
      <Toggle
        size="regular"
        isChecked={this.state.toggleOn}
        onChange={this.onToggleChange}
      />
    );  
  };

  renderStuff = () => {
    if (this.state.toggleOn) {
      return (
        <p>
          I will only be shown when the toggle is on.
        </p>
      );
    } else {
      return null;
    }
  };

  renderSound = () => {
    return (
      <audio
        controls
      >
        <source src="/media/FoodlandAnthem.mp3" />
      </audio>
    );
  };

  render() {
    return (
      <ContentWrapper>
        <PageTitle>National Anthem</PageTitle>
       The National Anthem of foodland was composed 
       and performed by their favorite composer Remy Morrow. 
       He is the best composer in Foodland's history.
       This is the Foodland national Anthem.

        <div className="newMainSection">
          {this.renderSound()}
        </div>
      </ContentWrapper>
    );
  }
}
