import ListenerGroup from '../../shared/util/ListenerGroup';

export class FlagActions {

  //listeners = [];
  listenerGroup = new ListenerGroup('FlagActions');

  addFlag = (flag) => {
    this._notifyListeners(flag);
  };

  registerListener = (listener) => {
    this.listenerGroup.registerListener(listener);
  };

  unregisterListener = (listener) => {
    this.listenerGroup.unregisterListener(listener);
  };

  _notifyListeners = (flag) => {
    this.listenerGroup.notifyListeners(flag);
  };

}

export default new FlagActions();
