import ListenerGroup from '../util/ListenerGroup';

export class GlobalSpinnerController {

  spinIdsToStartTimes = {};

  listenerGroup = new ListenerGroup('GlobalSpinnerController');

  getCurrentSignalToEdit = () => {
    return this.currentSignalToEdit;
  };

  isSpinning = () => {
    return Object.keys(this.spinIdsToStartTimes).length > 0;
  };

  startSpinning = (uuid) => {
    const previouslySpinning = this.isSpinning();
    const startTime = new Date().getTime();
    this.spinIdsToStartTimes[uuid] = startTime;
    const currentlySpinning = this.isSpinning();
    if (currentlySpinning !== previouslySpinning) {
      this.notifyListeners(currentlySpinning);
    }
  };

  stopSpinning = (uuid) => {
    const previouslySpinning = this.isSpinning();
    delete this.spinIdsToStartTimes[uuid];
    const currentlySpinning = this.isSpinning();
    if (currentlySpinning !== previouslySpinning) {
      this.notifyListeners(currentlySpinning);
    }
  };

  notifyListeners = (spinning) => {
    this.listenerGroup.notifyListeners(spinning);
  };

  registerListener = (listener) => {
    this.listenerGroup.registerListener(listener);
  };

  unregisterListener = (listener) => {
    this.listenerGroup.unregisterListener(listener);
  };

}

export default new GlobalSpinnerController();
