import React, { PureComponent } from "react";
import PropTypes from 'prop-types';
import Avatar from '@atlaskit/avatar';
import { Link } from 'react-router-dom';
import logo from '../../images/logo.png';
import Nav, {
  AkContainerTitle,
  AkCreateDrawer,
  AkNavigationItem,
  AkSearchDrawer,
} from '@atlaskit/navigation';
import teamAppNav from './NavItems';

import SearchIcon from '@atlaskit/icon/glyph/search';
import ClickNavItem from './ClickNavItem';
import CreateIcon from '@atlaskit/icon/glyph/add';
import constants from '../../../shared/model/Constants';
import CreateDrawer from './CreateDrawer';
import SearchDrawer from './SearchDrawer';
import HelpDropdownMenu from './HelpDropdownMenu';
import AccountDropdownMenu from './AccountDropdownMenu';

import AtlassianIcon from '@atlaskit/icon/glyph/atlassian';
import ArrowleftIcon from '@atlaskit/icon/glyph/arrow-left';

import ReactTooltip from 'react-tooltip';
// import logo from '../../images/logo.svg';
import SignOutIcon from '@atlaskit/icon/glyph/sign-out';

export default class NavBar extends PureComponent {

  mounted = false;

  constructor(props) {
    super(props);
    const navInfo = teamAppNav.getNavInfo();
    this.state = {
      navInfo: navInfo,
      timeSpanTooltip: ''
    };
  }

  static contextTypes = {
    navOpenState: PropTypes.object,
    router: PropTypes.object,
  };

  componentWillMount() {
    //this.setState({
    //});
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  openDrawer = (openDrawer) => {
    this.setState({ openDrawer });
  };

  shouldComponentUpdate(nextProps, nextContext) {
    return true;
  };

  onItemClicked = (event, handler) => {
    debugger;
    if (handler) {
      event.preventDefault();
      handler();
    } else {
      this.props.onItemClicked(event);
    }
  };

  renderMenuItemInner = (Icon, text) => {
    return (
      <div className="toolbar-left" style={{fontSize: '24px'}}>
        <div className="toolbar-item">
          <Icon label={text} size="medium" />
        </div>
        <div className="toolbar-item">
          {text}
        </div>
      </div>
    );
  };

  renderMenuItem = (nav) => {
    const url = nav.path;
    const title = nav.title;
    const Icon = nav.icon;
    const handler = nav.handler;
    const context = this.context;
    const router = context.router;
    const selected = router.route.location.pathname === url;
    if (title && Icon) {
      if (handler) {
        return (
          <div className="toolbar-item">
            {this.renderMenuItemInner(Icon, title)}
          </div>
        );
      } else {
        return (
          <div className="toolbar-item">
            <Link key={url} to={url}>
              {this.renderMenuItemInner(Icon, title)}
            </Link>
          </div>
        );
      }
    } else {
      // Just a route definition
      return null;
    }
  };

  renderNavLinks = () => {
    const renderedItems = this.state.navInfo.map(nav => {
      return this.renderMenuItem(nav);
    }, this);
    return (
      <div className="main-nav">
        <div className="toolbar-left">
          <div className="toolbar-item">
            <img src={logo} width="120px" style={{borderRadius: '10px'}}/>
          </div>
          <div className="toolbar-item">
            <div className="toolbar-left">
              {renderedItems}
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return this.renderNavLinks();
  }

}
