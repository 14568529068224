import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Flag, { FlagGroup } from '@atlaskit/flag';
import flagActions from '../actions/FlagActions';
import GlobalSpinner from '../components/widget/GlobalSpinner';
import Modal from '@atlaskit/modal-dialog';
import Page from '@atlaskit/page';
import '@atlaskit/css-reset';
//import dataMigrator from '../../backend/data/DataMigrator';

import AppNavigationBar from '../components/nav/AppNavigationBar';
import NavBar from '../components/nav/NavBar';

export default class App extends PureComponent {

  mounted = false;

  // static contextTypes = {
  //   navOpenState: PropTypes.object,
  //   router: PropTypes.object,
  // };

  // static PropTypes = {
  //   navOpenState: PropTypes.object,
  //   onNavResize: PropTypes.func,
  // };

  // static childContextTypes = {
  //   showModal: PropTypes.func,
  //   addFlag: PropTypes.func,
  // };

  constructor(props) {
    super(props);
    flagActions.registerListener(this.onAddFlag);
    this.state = {
      flags: [],
      isModalOpen: false,
      username: '',
    };
  }

  componentWillMount() {
    //dataMigrator.convertAndLogData();
    //dataMigrator.migrateData();
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onCurrentUserChange = (user) => {
    if (this.mounted) {
      this.setState({
        user: user
      });
    } else {
      const state = this.state;
      state.user = user;
    }
  };

  getChildContext() {
    return {
      showModal: this.showModal,
      addFlag: this.addFlag,
    };
  }

  showModal = () => {
    this.setState({ isModalOpen: true });
  };

  hideModal = () => {
    this.setState({ isModalOpen: false });
  };

  addFlag = (flag) => {
    this.setState({
      flags: [{ id: Date.now() }].concat(this.state.flags)
    });
  };

  onAddFlag = (flag) => {
    const newFlags = [flag].concat(this.state.flags);
    this.setState({
      flags: newFlags
    });
    if (flag.flagMessageTimeoutSeconds) {
      setTimeout(() => {
        this.removeFlagById(flag.id);
      }, flag.flagMessageTimeoutSeconds * 1000);
    }
  };

  removeFlagById = (id) => {
    this.setState({
      flags: this.state.flags.filter(flag => flag.id !== id),
    });
  };

  onFlagDismissed = (dismissedFlagId) => {
    this.setState({
      flags: this.state.flags.filter(flag => flag.id !== dismissedFlagId),
    })
  };

  renderAuthenticatedApp = () => {
    return (
      <Page
      >
        <NavBar/>
        {this.props.children}
      </Page>
    );
  };

  renderAuthenticatedApp___OLD = () => {
    const width = this.context.navOpenState ? this.context.navOpenState.width : 100;
    return (
      <Page
        navigationWidth={width}
        navigation={<AppNavigationBar />}
      >
        {this.props.children}
      </Page>
    );
  };

  render() {
    const renderedApp = this.renderAuthenticatedApp();
    return (
      <div>
        {renderedApp}
        <GlobalSpinner/>
        <div>
          <FlagGroup onDismissed={this.onFlagDismissed}>
            {
              this.state.flags.map(flag => (
                <Flag
                  id={flag.id}
                  key={flag.id}
                  appearance={flag.appearance}
                  title={flag.title}
                  description={flag.description}
                />
              ))
            }
          </FlagGroup>
          {
            this.state.isModalOpen && (
              <Modal
                heading="Candy bar"
                actions={[{ text: 'Exit candy bar', onClick: this.hideModal }]}
                onClose={this.hideModal}
              >
                <p style={{ textAlign: 'center' }}>
                  <img src="http://i.giphy.com/yidUztgRB2w2gtDwL6.gif" alt="Moar cupcakes" />
                </p>
              </Modal>
            )
          }
        </div>
      </div>
    );
  }
}
