import React, { PureComponent } from "react";
import Spinner from '@atlaskit/spinner';
import globalSpinnerController from '../../../shared/model/GlobalSpinnerController';

export default class GlobalSpinner extends PureComponent {

  mounted = false;
  state = {
    spinning: false
  };

  constructor(props) {
    super(props);
    //globalSpinnerController.registerListener(this.onSpinChange)
  }

  componentWillMount() {
    globalSpinnerController.registerListener(this.onSpinChange)
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    globalSpinnerController.unregisterListener(this.onSpinChange)
    this.mounted = false;
  }

  onSpinChange = (spinning) => {
    if (this.mounted) {
      this.setState({
        spinning: spinning
      });
    } else {
      const state = this.state;
      state.spinning = spinning;
    }
  };

  renderSpinner = () => {
    return (
      <div
        style={{
            zIndex: 1000,
            position: 'absolute',
            left: 'calc(50% - 100px)',
            top: '30%'
          }}
        >
        <div>
          <Spinner size="xlarge"/>
        </div>
      </div>
    );
  };

  render() {
    if (this.state.spinning) {
      return this.renderSpinner();
    } else {
      return null;
    }
  }

}

