import PropTypes from 'prop-types';
import React from 'react';
import Avatar from '@atlaskit/avatar';
import { Link } from 'react-router-dom';
import Nav, {
  AkContainerTitle,
  AkCreateDrawer,
  AkNavigationItem,
  AkSearchDrawer,
} from '@atlaskit/navigation';
import teamAppNav from './NavItems';

import SearchIcon from '@atlaskit/icon/glyph/search';
import ClickNavItem from './ClickNavItem';
import CreateIcon from '@atlaskit/icon/glyph/add';
import constants from '../../../shared/model/Constants';
import CreateDrawer from './CreateDrawer';
import SearchDrawer from './SearchDrawer';
import HelpDropdownMenu from './HelpDropdownMenu';
import AccountDropdownMenu from './AccountDropdownMenu';

import AtlassianIcon from '@atlaskit/icon/glyph/atlassian';
import ArrowleftIcon from '@atlaskit/icon/glyph/arrow-left';

import ReactTooltip from 'react-tooltip';
import logo from '../../images/logo.svg';
import SignOutIcon from '@atlaskit/icon/glyph/sign-out';

export default class AppNavigationBar extends React.Component {

  mounted = false;

  constructor(props) {
    super(props);
    const navInfo = teamAppNav.getNavInfo();
    this.state = {
      navInfo: navInfo,
      timeSpanTooltip: ''
    };
  }

  static contextTypes = {
    navOpenState: PropTypes.object,
    router: PropTypes.object,
  };

  componentWillMount() {
    //this.setState({
    //});
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  openDrawer = (openDrawer) => {
    this.setState({ openDrawer });
  };

  shouldComponentUpdate(nextProps, nextContext) {
    return true;
  };

  onItemClicked = (event, handler) => {
    debugger;
    if (handler) {
      event.preventDefault();
      handler();
    } else {
      this.props.onItemClicked(event);
    }
  };

  renderNavLinks = () => {
    return this.state.navInfo.map(nav => {
      const url = nav.path;
      const title = nav.title;
      const Icon = nav.icon;
      const handler = nav.handler;
      const context = this.context;
      const router = context.router;
      const selected = router.route.location.pathname === url;
      if (title && Icon) {
        if (handler) {
          return (
            <AkNavigationItem
              icon={<Icon label={title} size="medium" />}
              text={title}
              isSelected={selected}
              onClick={handler}
            />
          );
        } else {
          return (
            <Link key={url} to={url}>
              <AkNavigationItem
                icon={<Icon label={title} size="medium" />}
                text={title}
                isSelected={selected}

                />
            </Link>
          );
        }
      } else {
        // Just a route definition
        return null;
      }
    }, this);
  };

  render() {
    const backIcon = <ArrowleftIcon label="Back icon" size="medium" />;
    const globalPrimaryIcon = <AtlassianIcon label="Atlassian icon" size="xlarge" />;

    return (
      <Nav
        isOpen={this.context.navOpenState.isOpen}
        width={this.context.navOpenState.width}
        onResize={this.props.onNavResize}
        containerHeaderComponent={() => (
          <AkContainerTitle
            href="#foo"
            icon={
              <img alt="Team Ops" src={logo} />
            }
            text={constants.countryName}
          />
        )}
        globalPrimaryIcon={globalPrimaryIcon}
        globalPrimaryItemHref="/"
        globalSearchIcon={<SearchIcon label="Search icon" />}
        hasBlanket
        drawers={[
          <AkSearchDrawer
            backIcon={backIcon}
            isOpen={this.state.openDrawer === 'search'}
            key="search"
            onBackButton={() => this.openDrawer(null)}
            primaryIcon={globalPrimaryIcon}
          >
            <SearchDrawer
              onResultClicked={() => this.openDrawer(null)}
              onSearchInputRef={(ref) => {
                this.searchInputRef = ref;
              }}
            />
          </AkSearchDrawer>,
          <AkCreateDrawer
            backIcon={backIcon}
            isOpen={this.state.openDrawer === 'create'}
            key="create"
            onBackButton={() => this.openDrawer(null)}
            primaryIcon={globalPrimaryIcon}
          >
            <CreateDrawer
              onItemClicked={() => this.openDrawer(null)}
            />
          </AkCreateDrawer>
        ]}
        globalAccountItem={AccountDropdownMenu}
        globalCreateIcon={<CreateIcon label="Create icon" />}
        globalHelpItem={HelpDropdownMenu}
        onSearchDrawerOpen={() => this.openDrawer('search')}
        onCreateDrawerOpen={() => this.openDrawer('create')}
      >
        {this.renderNavLinks()}
      </Nav>
    );
  }
}
