import PropTypes from 'prop-types';
import React, { PureComponent } from "react";

import { AkNavigationItemGroup, AkNavigationItem } from "@atlaskit/navigation";

import AddItemIcon from '@atlaskit/icon/glyph/add-item';
import BitbucketBranchesIcon from "@atlaskit/icon/glyph/bitbucket/branches";
import PageIcon from "@atlaskit/icon/glyph/page";

const createItems = [
  {
    title: 'Quick operations',
    items: [
      ['/#add-something', 'Add something', 'Add something', AddItemIcon, () => {alert('Not implemented')}],
    ]
  },
];

export default class CreateDrawer extends PureComponent {
  // static PropTypes = {
  //   onItemClicked: PropTypes.func
  // };

  onItemClicked = (event, handler) => {
    if (handler) {
      event.preventDefault();
      handler();
    } else {
      this.props.onItemClicked(event);
    }
  };

  render() {
    return (
      <div>
        {
          createItems.map(itemGroup => {
            return (
              <AkNavigationItemGroup key={itemGroup.title} title={itemGroup.title}>
                {
                  itemGroup.items.map(item => {
                    const [url, text, label, Icon, handler] = item;
                    return (
                      <AkNavigationItem
                        key={url}
                        href={url}
                        icon={<Icon label={label}/>}
                        text={text.valueOf()}
                        onClick={(event) => this.onItemClicked(event, handler)}
                      />
                    );
                  })
                }
              </AkNavigationItemGroup>
            )
          })
        }
      </div>
    )
  };
}
